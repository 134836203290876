import React, {Component} from 'react';
import {getURLParameter} from "../utils/HelpfulFunction";

class ImageViewer extends Component {
    constructor(props) {
        super(props);
        const imageToShow = getURLParameter('image')
        this.state = {
            imageToShow: imageToShow
        };
    }

    render() {
        return (
            <img src={this.state.imageToShow} style={{maxWidth: "100%", maxHeight: "100%"}} alt=""/>
        )
    }
}

export default ImageViewer;
